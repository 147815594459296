<template>
    <PlayGround></PlayGround>
</template>

<script>
import PlayGround from '@/components/PlayGround.vue'

export default {
    name: "RecordContentView",
    components: {
        PlayGround,
    },
    setup() {
    }
}
</script>

<style scoped></style>
